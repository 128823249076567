#unete {
  text-align: center;
  background-image: url('../images/HELECHO.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  color: white;
  position: relative;
  padding: 50px 0;
  .text-container {
    position: absolute;
    width: calc(100% - 20px);
    bottom: 10%;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.712);
    display: flex;
    flex-direction: column;
    a {
      padding: 5px;
    }
  }
  h2 {
    width: 50%;
    padding: 10px;
    margin: 20px auto;
    background-color: rgba(0, 0, 0, 0.712);
  }
}

@media screen and (max-width: 600px) {
  #unete {
    background-position-x: -370px;
  }
}
