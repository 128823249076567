.navbar-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 10%;
  width: 100%;
  height: 72px;
  box-shadow: 1px 4px 35px 0px rgba(0, 0, 0, 0.47);
  position: fixed;
  top: 0;
  z-index: 200;
  background-color: white;
  .navbar-content {
    max-width: 1440px;
    width: 100%;
    padding: 0 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .navbar-social {
      margin-right: 20px;
      display: flex;
      flex-direction: row;
      img {
        width: 25px;
        height: 25px;
        object-fit: contain;
        padding: 5px;
        // margin: 10px;
      }
    }
    .logo-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-left: 10px;
      width: 100%;
      font-size: 16px;
      h4 {
        font-weight: 650;
      }
      .navbar-logo {
        width: 50px;
        height: 50px;
        margin-right: 10px;
      }
    }

    .navbar-spacer {
      flex-grow: 1;
    }
    .links-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      & :last-child {
        border: none;
      }
      a {
        margin: 5px;
        text-decoration: none;
        color: #333;
        &:hover {
          text-decoration: underline;
        }
        text-align: left;
        border-right: 1px solid #333;
        padding-right: 5px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .navbar-wrapper {
    height: 100px;
    padding: 10px 5px;
    justify-content: flex-start;
    padding: 0 4%;
    margin-left: 0;
    .navbar-content {
      flex-direction: row;
      flex-wrap: wrap;
      .links-wrapper {
        width: 100%;
        a {
          font-size: 12px;
        }
      }
      .links-wrapper {
        a {
          text-align: center;
          height: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .logo-container {
        font-size: 10px;
        max-width: 180px;
        margin-left: 20px;

        .navbar-logo {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}
