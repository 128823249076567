@import "~react-image-gallery/styles/scss/image-gallery.scss";

@import "~react-image-gallery/styles/css/image-gallery.css";

img.image-gallery-image{
    height: 700px;
    object-fit: cover !important;
}

.image-gallery-content .image-gallery-slide {
    object-fit: cover !important;
}
// .image-gallery-content .image-gallery-slide .image-gallery-image {
//     max-height: calc(100vh - 80px);
// }

@media screen and (max-width: 1024px){
    img.image-gallery-image{
        max-height: 500px !important;
    }
}