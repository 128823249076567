.App {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  width: 100vw;
}

.main {
  margin-top: 72px;
  max-width: 1300px;
  h3 {
    margin-bottom: 30px;
    font-weight: 800;
  }
  h2 {
    margin: 50px 0;
    font-size: 36px;
  }
  p {
    font-size: 16px;
    text-align: left;
    font-weight: 500;
  }
  section {
    margin-bottom: 50px;
    p {
      font-size: 18px;
      line-height: 30px;
      text-align: center;
    }
  }
}

html {
  scroll-behavior: smooth;
}

@media (max-width: 600px) {
  .main {
    margin-top: 100px !important;
  }
}
