#equipo {
	text-align: center;
	h2 {
		margin: 50px 0;
		font-size: 36px;
		color: #333;
	}
	.people-container {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: center;
		align-items: flex-start;

		.person-wrapper {
			width: 45%;
			min-width: 200px;
			// height: 250px;
			margin: 25px;
			img {
				width: 300px;
				height: 200px;
				object-fit: cover;
				margin-bottom: 20px;
			}
			h5 {
				margin-top: 5px;
				color: rgb(97, 96, 96);
			}
			p {
				font-size: 12px;
				margin-top: 20px;
				text-align: justify;
			}
		}
	}
}

@media screen and (max-width: 600px) {
	#equipo {
		.people-container {
			.person-wrapper {
				width: 90%;
			}
		}
	}
}
