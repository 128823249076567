#proyectos {
  text-align: center;
  h2 {
    margin: 50px 0;
    font-size: 36px;
  }
  .double-project-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    border-top: 1px solid #333;
    padding-top: 50px;
    div {
      flex-wrap: wrap;
      padding: 20px;
    }
    iframe,
    img {
      width: 100%;
      margin-bottom: 50px;
    }
  }
  .project-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    border-top: 1px solid #333;
    padding-top: 50px;
    justify-content: center;
    flex-wrap: wrap;

    iframe,
    img {
      margin-bottom: 25px;
      width: 100%;
    }
    .text-container {
      margin: 0 auto;
      padding: 0 25px;
      h3 {
        margin-bottom: 30px;
        font-weight: 800;
      }
      p {
        font-size: 16px;
        text-align: justify;
      }
    }
    .extra-images-container {
      margin-top: 20px;
    }
  }
}

@media (min-width: 1024px) {
  #proyectos {
    text-align: center;
    h2 {
      margin: 50px 0;
      font-size: 36px;
    }
    .double-project-wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 50px;
      border-top: 1px solid #333;
      padding-top: 50px;

      div {
        flex-wrap: wrap;
      }
      iframe,
      img {
        width: 49%;
        margin: 0 5px;
      }
    }

    .project-wrapper {
      flex-direction: row;
      &:nth-child(odd) {
        flex-direction: row-reverse;
        p {
          margin-left: 0 !important;
          margin-right: 25px;
        }
      }
      iframe,
      img {
        margin-bottom: 0;
        width: 550px;
      }
      .text-container {
        padding: 0;
        max-width: 56%;

        p {
          font-size: 16px;
          text-align: left;
          margin-left: 50px;
          text-align: justify;
        }
      }
    }
  }
}
