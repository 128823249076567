#quienes {
  background-image: url('../images/quienes-somos/quienes-somos.jpg');
  height: 700px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    width: 75%;
    text-align: center;
    background-color: rgba($color: #000000, $alpha: 0.5);
    padding: 25px;
    color: white;
    box-shadow: #00000088 0px 3px 7px 0px;
    h2 {
      margin: 30px 0 !important;
      margin-top: 10px !important;
      font-size: 40px;
    }
  }
}

.ms-section {
  height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 25px;

  .spacer {
    display: none;
  }
  .text-container {
    width: 100%;
    text-align: center;
    background-color: rgba($color: #000000, $alpha: 0.6);
    padding: 25px;
    color: white;
    box-shadow: #00000088 0px 3px 7px 0px;
    h2 {
      margin-bottom: 25px;
      font-size: 30px;
    }
    p {
      font-size: 16px;
      text-align: justify;
      font-weight: 500;
    }
  }
}

.ms-section {
  div {
    p {
      text-align: left;
    }
  }
}

.mision {
  background-image: url('../images/quienes-somos/mision.jpg');
}

.vision {
  background-image: url('../images/quienes-somos/vision.jpg');
  .text-container {
  }
}

@media (min-width: 1024px) {
  #quienes-somos {
    div {
      width: 60%;
    }
  }
  .ms-section {
    .spacer {
      flex-grow: 1;
      display: unset;
    }
    padding: 50px;
    .text-container {
      width: 30%;
      h2 {
        font-size: 36px;
      }
      p {
        font-size: 18px;
      }
    }
  }
}
